export default {
  "general.account.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte\r"])},
  "general.countries.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des Pays-Bas\r"])},
  "general.countries.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Belgique\r"])},
  "general.languages.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Anglais\r"])},
  "general.languages.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néerlandais\r"])},
  "general.languages.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français\r"])},
  "registered.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre inscription\r"])},
  "registered.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos responsables d'événements vous contacteront sous peu avec des instructions et des possibilités supplémentaires.\r"])},
  "cta.registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la page d'aperçu de vos volontaires\r"])},
  "forms.motivations.heading.volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est votre motivation pour faire du bénévolat ?\r"])},
  "forms.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les informations fournies ne sont pas complètes ou valides , veuillez vérifier.\r"])},
  "forms.areas.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle région souhaitez-vous être actif en tant que volontaire ?\r"])},
  "forms.location.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue et localisation\r"])},
  "forms.location.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas de pays ou de tâche rempli\r"])},
  "registration.individual.tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel\r"])},
  "registration.group.tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe\r"])},
  "registration.group.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable\r"])},
  "registration.group.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer un nouveau volontaire\r"])},
  "registration.group.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire comme\r"])},
  "forms.remarks.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez-vous ici\r"])},
  "general.language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue\r"])},
  "general.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays\r"])},
  "forms.size.small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 - 10\r"])},
  "forms.size.medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11 - 40\r"])},
  "forms.size.large": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41+ ou plus\r"])},
  "forms.size.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun choix n'a été fait\r"])},
  "forms.size.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien de bénévoles pouvez-vous engager dans votre club/organisation ?\r"])},
  "general.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de votre club/organisation\r"])},
  "general.extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre motivation\r"])},
  "forms.extra.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun choix n'a été fait\r"])},
  "forms.club.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nom n'a été saisi\r"])},
  "general.firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom\r"])},
  "general.lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille\r"])},
  "forms.birthdate.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance\r"])},
  "forms.birthdate.errors.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une date de naissance n'a pas été renseignée\r"])},
  "forms.birthdate.errors.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, vous ne pouvez vous inscrire qu'à partir de l'année de vos 16 ans.\r"])},
  "general.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique\r"])},
  "forms.email.error.unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà un compte Letsmove.com associé à cette adresse électronique. Veuillez vous connecter avec votre compte existant et remplir le formulaire.\r"])},
  "forms.email.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse électronique est un champ obligatoire\r"])},
  "cta.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte Letsmove.com\r"])},
  "forms.email.error.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas d'adresse électronique valide saisie\r"])},
  "forms.locales.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans quelle région votre club/organisation est-il actif ?\r"])},
  "forms.locales.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un code postal dans la zone où votre club/organisation souhaite faire du bénévolat.\r"])},
  "forms.motivations.heading.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est la motivation de votre club/organisation pour faire du bénévolat ?\r"])},
  "forms.motivations.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cochez tout ce qui s'applique\r"])},
  "forms.motivations.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour gagner un peu d'argent supplémentaire\r"])},
  "forms.motivations.fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le plaisir, pour faire partie de l'événement\r"])},
  "forms.motivations.social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour l'aspect social\r"])},
  "forms.motivations.learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour acquérir de nouvelles expériences ou compétences\r"])},
  "forms.motivations.extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre motivation\r"])},
  "forms.postcode.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun code postal valide n'a été saisi\r"])},
  "general.postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal\r"])},
  "forms.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre\r"])},
  "forms.tel.error.numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez utiliser que des chiffres dans ce champ\r"])},
  "forms.tel.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone portable est un champ obligatoire\r"])},
  "general.tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone portable\r"])},
  "forms.remarks.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres remarques\r"])},
  "forms.responsible.me.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrez-moi comme responsable d'un groupe\r"])},
  "forms.responsible.me.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que responsable d'un club, vous recevrez toutes les communications de Golazo concernant l'événement.\r"])},
  "forms.responsible.other.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer quelqu'un d'autre comme personne responsable\r"])},
  "forms.responsible.other.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez la personne responsable enregistrer le groupe par le biais de ce formulaire. La personne responsable obtient un compte personnel pour gérer l'enregistrement du groupe.\r"])},
  "forms.responsible.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites un choix\r"])},
  "forms.privacy.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je déclare avoir lu le\r"])},
  "forms.privacy.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration de confidentialité.\r"])},
  "forms.pre-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces informations proviennent de votre compte Letsmove.com. Les ajouts sont automatiquement inscrits sur votre compte Letsmove.com.\r"])},
  "general.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe\r"])},
  "forms.password.panel.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un mot de passe\r"])},
  "forms.password.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestions\r"])},
  "forms.password.requirement-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une lettre minuscule\r"])},
  "forms.password.requirement-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une lettre majuscule\r"])},
  "forms.password.requirement-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un numéro\r"])},
  "forms.password.requirement-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 8 caractères\r"])},
  "forms.password.error.required.unused-translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre et un chiffre.\r"])},
  "forms.password.error.weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un mot de passe plutôt faible, mais nous l'acceptons.\r"])},
  "forms.password.error.strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok , mot de passe fort !\r"])},
  "general.days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours\r"])},
  "general.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois\r"])},
  "general.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Années\r"])},
  "forms.terms.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les conditions générales*\r"])},
  "forms.terms.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis d'accord pour\r"])},
  "forms.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous obtiendrez un compte individuel Letsmove.com avec lequel vous pourrez gérer votre profil de bénévole, tant pour les groupes que pour les particuliers.\r"])},
  "cta.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre\r"])},
  "logout.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au plaisir de vous voir bientôt\r"])},
  "logout.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déconnecté avec succès\r"])},
  "logout.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à Golazo\r"])},
  "logout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion\r"])},
  "cta.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à Golazo\r"])},
  "login.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion\r"])},
  "login.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel pour continuer\r"])},
  "login.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à l'inscription des volontaires\r"])},
  "login.form.email.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique non valide\r"])},
  "login.form.email.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous qu'il s'agit de votre adresse personnelle (e-mail ?)\r"])},
  "login.form.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller plus loin\r"])},
  "login.form.footer.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou\r"])},
  "login.form.footer.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une question ou besoin d'aide ?\r"])},
  "login.form.footer.privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique et conditions de confidentialité*\r"])},
  "login.form.footer.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau compte\r"])},
  "cta.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer\r"])},
  "overview.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répertoire des bénévoles\r"])},
  "overview.divider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénévoles\r"])},
  "cta.register.club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer un nouveau club ou une nouvelle organisation\r"])},
  "cta.register.volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire comme volontaire\r"])},
  "overview.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer\r"])},
  "faq.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faq\r"])},
  "faq.subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de toutes les questions pratiques que vous pourriez vous poser en tant que volontaire.\r"])},
  "cta.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la FAQ\r"])},
  "management.heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer l'inscription de vos volontaires\r"])},
  "management.description.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, je veux me retirer de la plateforme.\r"])},
  "management.description.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, je veux supprimer cette inscription de volontaire\r"])},
  "cta.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez que vous voulez supprimer cette\r"])},
  "toast.manage.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé avec succès\r"])},
  "forms.tasks.heading.organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel type de tâche(s) intéresse votre organisation ?\r"])},
  "forms.tasks.heading.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel type d'emploi/tâches vous intéresse ?\r"])},
  "forms.tasks.steward.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaleurs & stewards\r"])},
  "forms.tasks.steward.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable & fiable, tourné vers la ‘sécurité avant tout’, attentif, assertif et serviable\r"])},
  "forms.tasks.service.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Team\r"])},
  "forms.tasks.service.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur le terrain et résolution des problèmes, bricoleur et touche-à-tout, esprit d’équipe\r"])},
  "forms.tasks.welcome.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil et équipe info\r"])},
  "forms.tasks.welcome.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible, chaleureux, axé sur les solutions, joyeux, solidaire et créatif, administrativement fort et structuré, multilingue\r"])},
  "forms.tasks.green.main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green team\r"])},
  "forms.tasks.green.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soucieux de l’environnement d’une manière pratique, tourné vers l’avenir et la qualité, intéressé par le durable et le social\r"])},
  "countries.be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays-Bas\r"])},
  "countries.nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique\r"])},
  "forms.motivations.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun choix n'a été fait\r"])},
  "forms.tasks.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun choix n'a été fait\r"])},
  "forms.firstname.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun prénom n'a été saisi\r"])},
  "forms.lastname.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nom de famille n'a été saisi\r"])},
  "forms.general.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé\r"])},
  "forms.password.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mot de pas n'a été saisi\r"])},
  "forms.volunteer.error.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune autre motivation n'est spécifiée"])}
}